// ATTN we use these constants in data dump
const abTests = {
  // CRO tests
  tyHideAddedProduct: 'ty:hideAddedProduct', // CRO-270
  paymentOrderPreviewDropdown: 'payment:orderPreviewDropdown', // CRO-417
  globalSubscribeBannerUpdate: 'global:subscribeBannerUpdateV2', // CRO-488
  queuePageRedesign: 'queue:pageRedesign', // CRO-476
  scentProfileApplePay: 'scentProfile:applePay', // CRO-445
  popupHidePopups: 'popup:hidePopups', // CRO-475
  homeWhatYouGet: 'home:whatYouGet', // CRO-393
  heroServiceAndOfferDescriptionDivision: 'home:heroServiceAndOfferDescriptionDivision', // CRO-402
  globalHideCurrencySign: 'global:hideCurrencySign', // CRO-435
  paymentApplePayOnTop: 'payment:appleOnTop', // CRO-446
  tyDriftCarImage: 'ty:driftCarImage', // CRO-495
  tyCompactUpgrade: 'ty:compactUpgrade', // CRO-378
  // PF tests
  constructorRecommendations: 'constructorRecommendations', // PF-2319
} as const

export type AbTestName = typeof abTests[keyof typeof abTests]


export default abTests
